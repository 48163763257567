/**Import react section */
import React, { useState } from 'react'
import propTypes from 'prop-types'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

/**Import components section */
import SideBar from '../../common/DashboadSideBar'
import Toolbar from '../../common/Toolbar'
import TextBox from '../../common/TextBox'
import ButtonSaved from '../../common/Buttons/ButtonSaved'
import AlertComponent from '../../common/AlertComponent'
import { Layout, Content, Header, HeaderBreadCrum } from '../../layouts/AuthenticatedLayout'
import EquipmentsSapMobile from '../components/manual/EquipmentsSapMobile'
/** Import material UI */
import { Typography, Paper, FormHelperText, LinearProgress, Grid } from '@material-ui/core'

/**Import resources section */
import { useSerialSapMobileStyle } from '../resources/useStyles'
import iconSparePart from '../../../resources/images/sparePart.svg'
import { hasAuthorization } from '../../../store/session/sessionActions'
import { VISUALIZER } from '../../../resources/RolesEnum'
const SerialSapDesktopView = (props) => {
	/**onjects props sections */
	const { userProfile, equipmentsSap } = props
	/**Array props section */
	const { sparePartsSap } = props
	/**Functions props section */
	const { onConsultBySerie, onChangeQuerySap, onUpdateQuantityEquipment, onDonwloadReportBySerie, onRemoveFileJsonSerie } = props
	/**string props section */
	const { querySparePart } = props
	/**bool props section */
	const { isLoadingFromSAP, isDownloadReport } = props

	const classes = useSerialSapMobileStyle()
	const [serialNumber, setSerialNumber] = useState('')
	const [isVisibleHelper, setIsVisibleHelper] = useState(false)
	// let canBeRemoveFileJsonSerie = hasAuthorization(userProfile, [PORTAL_ADMINISTRATOR, DISPATCHER])
	let canBeRemoveFileJsonSerie = userProfile && userProfile.userType === 'ActiveDirectoryUser' && !hasAuthorization(userProfile, [VISUALIZER])
	const onPropertyChange = (event) => {
		let { value } = event.target
		setSerialNumber(value)
	}

	const onSearchSerie = (event) => {
		if (onConsultBySerie && serialNumber.length === 12) {
			setIsVisibleHelper(false)
			onConsultBySerie(serialNumber)
		} else {
			setIsVisibleHelper(true)
		}
	}

	const onCleanSerieSap = () => {
		if (onUpdateQuantityEquipment) {
			setSerialNumber('')
			onUpdateQuantityEquipment(null)
		}
	}

	const onRemoveFileJsonSerieSap = () => {
		if (onRemoveFileJsonSerie) {
			setSerialNumber('')
			onRemoveFileJsonSerie(serialNumber)
		}
	}

	return (
		<Layout>
			<Header
				key={'AuthenticatedHeader'}
				user={props.user}
				breadcrumbs={
					<HeaderBreadCrum>
						<Typography color='secondary'>Serial history</Typography>
					</HeaderBreadCrum>
				}
			/>
			<SideBar key={'AuthenticatedSideBar'} onChangeLocation={props.onChangeLocation} selectedButtonIndex={12} userProfile={userProfile} />
			<Content key={'AuthenticatedContent'}>
				<div className={classes.root}>
					<Toolbar title='SEARCH SERIE FROM SAP' icon={iconSparePart} />
					<Paper className={classes.paperSearch}>
						<Typography variant='subtitle2'>Enter the serial number of your Imbera product to get the information from SAP</Typography>
						<Grid container spacing={1}>
							<Grid item xs={8}>
								<TextBox
									active={true}
									fullWidth
									name='serialNumber'
									variant='outlined'
									margin='dense'
									label={'MFG Serial number'}
									onChange={onPropertyChange}
									value={serialNumber}
								/>
							</Grid>
							<Grid item xs={4} style={{ marginTop: '8px' }}>
								<ButtonSaved title='Search' isSaving={serialNumber.length !== 12} onSavedClick={onSearchSerie} />
							</Grid>
						</Grid>

						{isVisibleHelper && <FormHelperText error>An Imberar serial number consists of 12 digits</FormHelperText>}
						{isLoadingFromSAP && (
							<>
								<br />
								<AlertComponent
									title={'Getting series from SAP'}
									message={'This process may take a while, please wait...'}
									severityType={'info'}
								/>
								<LinearProgress />
							</>
						)}
					</Paper>
					<br />
					<EquipmentsSapMobile
						equipmentsSap={equipmentsSap}
						onChangeQuerySap={onChangeQuerySap}
						sparePartsSap={sparePartsSap}
						querySparePart={querySparePart}
						isModeCreate={false}
						onUpdateQuantityEquipment={onCleanSerieSap}
						isDownloadReport={isDownloadReport}
						onDonwloadReportBySerie={onDonwloadReportBySerie}
						canBeRemoveFile={canBeRemoveFileJsonSerie}
						onRemoveFileJsonSerieSap={onRemoveFileJsonSerieSap}
					/>
				</div>
			</Content>
		</Layout>
	)
}

SerialSapDesktopView.propTypes = {
	/**Determinate user logged */
	userProfile: propTypes.object.isRequired,
	/**Determinate equipmen get from sap*/
	equipmentsSap: propTypes.object,
	/**Spare parts from sap */
	sparePartsSap: propTypes.array.isRequired,

	/**Function invoke callback for consult serie fro sap*/
	onConsultBySerie: propTypes.func.isRequired,
	/**Function invoke callback for change query redux store */
	onChangeQuerySap: propTypes.func.isRequired,
	/**Function invoke callback for update quatity in redux store */
	onUpdateQuantityEquipment: propTypes.func.isRequired,
	/**
	 * Download report by serie for spare part
	 */
	onDonwloadReportBySerie: propTypes.func.isRequired,

	/**Determinate if get information from backend */
	isLoadingFromSAP: propTypes.bool.isRequired,
	/**
	 * Determinate if is download report by serie for spare parts
	 */
	isDownloadReport: propTypes.bool,
	querySparePart: propTypes.string
}
SerialSapDesktopView.defaultProps = {
	userProfile: {},
	equipmentsSap: {},
	sparePartsSap: [],
	isLoadingFromSAP: false,
	isDownloadReport: false,
	querySparePart: '',
	onConsultBySerie: () => console.warn('Callback [onConsultBySerie] no defined'),
	onChangeQuerySap: () => console.warn('Callback [onChangeQuerySap] no defined'),
	onUpdateQuantityEquipment: () => console.warn('Callback [onUpdateQuantityEquipment] no defined'),
	onDonwloadReportBySerie: () => console.warn('Callback [onDonwloadReportBySerie] no defined')
}
export default withImmutablePropsToJS(SerialSapDesktopView)
