/**Import react section */
import React, { useState, useEffect } from 'react'
import { useRouteMatch } from 'react-router-dom'
import propTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'
import Select from 'react-select'

import { Fab, Typography, DialogContent, Chip } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Clear'
import { isNullOrEmpty } from '../../store/helpers/StringHelper'
import { ServicesStatusTypes, ASSIGNED_SERVICE_PROVIDER_TECH } from '../../store/helpers/AppConstants'
import { SERVICE_PROVIDER_MANAGER } from '../../resources/RolesEnum'

/**Import components section */
import LateralPanel from '../common/lateralPanel/LateralPanel'
import AssgmentTechServiceProvider from './components/StatusPanel/AssgmentTechServiceProvider'
import Toaster from '../common/Toaster'
import CompletedService from './components/StatusPanel/CompletedService'

/**Import actions sections */
import { assignerdServiceProviderTech, completedService } from '../../store/services/serviceActions'
import { panelLateralServicesStyles } from '../../resources/styles/PanelLateralServicesStyles'
import iconFailureDoor from '../../resources/images/failureWarranty/door.svg'
import { hasAuthorization } from '../../store/session/sessionActions'

const EditServiceProvider = (props) => {
	/**Custom hooks sections */
	const dispatch = useDispatch()
	const { lateralPanelProviderConfig, openLateralPanelAssignedProvider, isSavingService, userProfile } = props
	/**State local for this component*/
	const [toasterOptions, setToasterOptions] = useState({
		open: false,
		variant: null,
		message: null
	})
	let match = useRouteMatch()
	const serie = match.params.serie
	console.log('🚀 ~ file: EditServiceProvider.js:49 ~ EditServiceProvider ~ serie:', serie)
	const [service, setService] = useState(null)
	let canBeAddTechProvider = hasAuthorization(userProfile, [SERVICE_PROVIDER_MANAGER])

	useEffect(() => {
		setService(lateralPanelProviderConfig.service)
	}, [lateralPanelProviderConfig.service])

	/**
	 * Function to assigned Service to Tech Assigned
	 * @param {*} series
	 */
	const onAssignerdServiceProviderTech = (assignementModel) => {
		return dispatch(assignerdServiceProviderTech(service.Token, assignementModel))
			.then(() => {
				openLateralPanelAssignedProvider()
				setToasterOptions({
					open: true,
					message: 'Service assigned successfully',
					variant: 'success'
				})
				return Promise.resolve()
			})
			.catch((error) => {
				setToasterOptions({
					open: true,
					message: !isNullOrEmpty(error) ? error : 'An error to assigned service to tech assigned',
					variant: 'warning'
				})
			})
	}

	/**
	 * Function to completed service
	 * @param {*} paswordModel
	 */
	const onCompletedService = (paswordModel) => {
		console.log('🚀 ~ file: EditServiceProvider.js:77 ~ onCompletedService ~ service:', service)
		return dispatch(completedService(service.Token, service.Ticket, paswordModel, serie))
			.then((service) => {
				openLateralPanelAssignedProvider()
				setToasterOptions({
					open: true,
					message: 'Service completed success',
					variant: 'success'
				})
				return Promise.resolve()
			})
			.catch((error) => {
				setToasterOptions({
					open: true,
					message: !isNullOrEmpty(error) ? error : 'An error to completed servie',
					variant: 'warning'
				})
			})
	}

	return (
		<>
			{lateralPanelProviderConfig.opened && (
				<LateralPanel onClose={openLateralPanelAssignedProvider}>
					<ChangeProviderPanel
						onCloseButtonClick={openLateralPanelAssignedProvider}
						service={service}
						isSavingService={isSavingService}
						onAssignementTechServiceProvider={onAssignerdServiceProviderTech}
						onCompletedService={onCompletedService}
						canBeAddTechProvider={canBeAddTechProvider}
					/>
				</LateralPanel>
			)}

			{/** Toaster */}
			{toasterOptions.open && <Toaster toasterOptions={toasterOptions} onClose={() => setToasterOptions({ open: false })} />}
		</>
	)
}

EditServiceProvider.props = {
	/**Service to change status*/
	lateralPanelProviderConfig: propTypes.shape({
		opened: propTypes.bool.isRequired,
		service: propTypes.object.isRequired
	}),
	/**open and close lateral panel config*/
	openLateralPanelAssignedProvider: propTypes.func,
	/**Determinate if is saving data in the back end */
	isSavingService: propTypes.bool.isRequired
}
EditServiceProvider.defaultProps = {
	lateralPanelProviderConfig: {
		opened: false,
		service: {}
	},
	isSavingService: false,
	openLateralPanelAssignedProvider: () => console.warn('Callback [openLateralPanelAssignedProvider] no defined')
}

export default withImmutablePropsToJS(EditServiceProvider)

/**
 * ChangeProviderPanel
 * @param {*} props
 */
const ChangeProviderPanel = (props) => {
	/**Asigned props cmponent */
	const { isSavingService, onAssignementTechServiceProvider, onCloseButtonClick, onCompletedService, canBeAddTechProvider } = props
	let match = useRouteMatch()
	const serie = match.params.serie
	const [serviceModel, setServiceModel] = useState(null)
	const [changeStatusModel, setStatusModel] = useState(null)

	useEffect(() => {
		setServiceModel(props.service)
	}, [props.service])

	/** Invoke when select property change*/
	const onSelectChange = (name, option) => {
		setStatusModel({
			...changeStatusModel,
			[name]: option
		})
	}

	const backgroundColorSelect = () => {
		let status = changeStatusModel ? changeStatusModel.status.value : null
		switch (Number(status)) {
			case ASSIGNED_SERVICE_PROVIDER_TECH:
				return changeStatusModel.status.color
			case ServicesStatusTypes.COMPLETED:
				return changeStatusModel.status.color
			default:
				return null
		}
	}

	const GetComponentByStatus = (status) => {
		switch (Number(status)) {
			case ASSIGNED_SERVICE_PROVIDER_TECH:
				return (
					<AssgmentTechServiceProvider
						serviceProviderTechAssigned={serviceModel ? serviceModel.ServiceProviderTechAssigned : null}
						onAssignementTechServiceProvider={onAssignementTechServiceProvider}
						isSavingService={isSavingService}
						isAuthorizadoForEditedData={true}
					/>
				)
			case ServicesStatusTypes.COMPLETED:
				return (
					<CompletedService
						isProvider={true}
						isSavingService={isSavingService}
						onSetPasswordDialogConfiguration={onCompletedService}
						status={changeStatusModel ? changeStatusModel.status.value : null}
					/>
				)
			default:
				return null
		}
	}

	const backGroundColorDefaultByStatus = () => {
		let status = serviceModel ? serviceModel.Status : null
		switch (Number(status)) {
			case ASSIGNED_SERVICE_PROVIDER_TECH:
				return '#64C4FC'
			case ServicesStatusTypes.COMPLETED:
				return '#3AC47D'
			default:
				return '#5357DC'
		}
	}

	const getPlaceholder = () => {
		switch (Number(serviceModel ? serviceModel.Status : null)) {
			case ServicesStatusTypes.ASSIGNED:
				return 'SERVICE ASSIGNED'
			case ServicesStatusTypes.WAITING:
				return 'SERVICE WAITING'
			case ServicesStatusTypes.COMPLETED:
				return 'SERVICE COMPLETED'
			case ASSIGNED_SERVICE_PROVIDER_TECH:
				return 'ASSIGN TECHNICAL PROVIDER'
			default:
				return null
		}
	}

	const colourSelectorStyles = {
		control: (styles) => ({
			...styles,
			backgroundColor: changeStatusModel ? backgroundColorSelect() : backGroundColorDefaultByStatus(),
			color: 'white'
		}),
		option: (styles, { data, isDisabled, isFocused, isSelected }) => {
			return {
				...styles,
				backgroundColor: isDisabled ? null : isSelected ? `${data.color}80` : isFocused ? `${data.color}80` : null,
				cursor: isDisabled ? 'not-allowed' : 'default',
				':active': {
					...styles[':active'],
					backgroundColor: !isDisabled && `${data.color}80`
				}
			}
		},
		input: (styles) => ({ ...styles, ...colorSelected() }),
		placeholder: (styles) => ({ ...styles, ...colorSelected() }),
		singleValue: (styles, { data }) => ({
			...styles,
			...colorSelected(data.color)
		}),
		menu: (styles) => ({ ...styles, zIndex: 10, maxHeight: 200 }),
		menuPortal: (styles) => ({ ...styles, zIndex: 9999 })
	}

	return (
		<>
			<PanelHeader
				onCloseButtonClick={onCloseButtonClick}
				title={'ASSIGN TECHNICIAN'}
				ticket={serviceModel ? serviceModel.Ticket : ''}
				serie={serie}
			/>
			<DialogContent>
				<Select
					id={'is-status'}
					placeholder={getPlaceholder()}
					name='status'
					autoFocus
					options={
						serviceModel
							? canBeAddTechProvider
								? serviceModel.GetAvailableOperationsProvider
								: serviceModel.GetOperationsTechProvider
							: null
					}
					styles={colourSelectorStyles}
					formatOptionLabel={formatOptionLabel}
					onChange={(value, action) => {
						onSelectChange(action.name, value)
					}}
				/>
				<br />
				<br />
				{GetComponentByStatus(changeStatusModel ? changeStatusModel.status.value : null)}
			</DialogContent>
		</>
	)
}

const colorSelected = (color = '#ccc') => ({
	alignItems: 'center',
	display: 'flex',
	color: 'white'
})

const formatOptionLabel = ({ value, label, color }) => (
	<div>
		<Chip id={value} size='small' label={label} clickable style={{ color: 'white', background: color }} />
	</div>
)

ChangeProviderPanel.props = {
	/**open and close lateral panel config*/
	onCloseButtonClick: propTypes.func,
	/**Determinate if is saving data in the back end */
	isSavingService: propTypes.bool.isRequired,
	/**Asigned on tech from service provider, in the service */
	onAssignementTechServiceProvider: propTypes.func
}
ChangeProviderPanel.defaultProps = {
	isSavingService: false,
	onCloseButtonClick: () => console.warn('Callback [onCloseButtonClick] no defined'),
	onAssignementTechServiceProvider: () => console.warn('Callback [onAssignementTechServiceProvider] no defined')
}

/**
 * PanelHeader
 * @param {*} props
 */
const PanelHeader = (props) => {
	const { title } = props
	const { onCloseButtonClick, ticket, serie } = props
	const classes = panelLateralServicesStyles()

	return (
		<div className={classes.panelHeader}>
			<div className={classes.containerHeader}>
				<div className={classes.containerIcon}>
					<Fab size='medium' className={classes.faultWarrantyIconSelected} disabled={true}>
						<img src={iconFailureDoor} alt='warranty-icon' className={classes.faultWarrantyIcon} />
					</Fab>
				</div>
				<div className={classes.containerText}>
					<Typography gutterBottom variant='subtitle1' className={classes.title}>
						{title || ''}
					</Typography>
					<Typography gutterBottom variant='subtitle2' className={classes.subTitle}>{`TICKET: ${ticket || ''}`}</Typography>
					{(serie !== '-' || !serie === 'null') && (
						<Typography gutterBottom variant='subtitle2' className={classes.subTitle}>{`SERIE: ${serie || ''}`}</Typography>
					)}
				</div>
			</div>
			<div className={classes.grow} />
			{!!onCloseButtonClick && (
				<Fab className={classes.buttonClose} onClick={onCloseButtonClick}>
					<DeleteIcon />
				</Fab>
			)}
		</div>
	)
}
PanelHeader.propTypes = {
	/**
	 * On change button close
	 */
	onCloseButtonClick: propTypes.func
}
