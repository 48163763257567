import React from 'react'
import propTypes from 'prop-types'

import AttachIcon from '@material-ui/icons/AttachFile'
import CloseIcon from '@material-ui/icons/Cancel'
import ViewIcon from '@material-ui/icons/CloudDownload'
import IconButton from '@material-ui/core/IconButton'

import ListSubheader from '@material-ui/core/ListSubheader'
import GridList from '@material-ui/core/GridList'
import GridListTile from '@material-ui/core/GridListTile'
import GridListTileBar from '@material-ui/core/GridListTileBar'

/**Import resources section */
import { createServicesStyle } from '../../../resources/styles/CreateServiceStyles'
import DropDownZone from '../../common/DropDownZone'

/**Import styles section */
import { Alert } from '@material-ui/lab'
/**Import Material Ui section */
import { Typography, Chip, Divider, Grid } from '@material-ui/core'

const ImagesComponent = (props) => {
	const classes = createServicesStyle()
	const { Attachments, onAddAttachments, onRemoveAttachments, canBeEdit } = props

	/**
	 * Function to get list image to show un card
	 */
	const imagesList = () => {
		return Attachments.filter((x) => {
			return x.isImagen === true
		})
	}

	return (
		<Grid container>
			<Grid item xs={12}>
				<Typography className={classes.title}>
					<strong>{'FILES SERVICES'}</strong>
				</Typography>
				<Divider />

				{canBeEdit && (
					<div style={{ width: '320px' }}>
						<DropDownZone
							classes={classes}
							multiple={false}
							message={'Click here to select files or image'}
							onFilesReceived={(files) => {
								onAddAttachments(files)
							}}
						/>
					</div>
				)}

				{imagesList().length !== 0 && (
					<div className={classes.rootFiles}>
						<GridList className={classes.gridListFileMovile}>
							<GridListTile key='Subheader' cols={2} style={{ height: 'auto' }}>
								<ListSubheader component='div'>IMAGES</ListSubheader>
							</GridListTile>
							{imagesList().map((file) => (
								<GridListTile key={file.name} className={classes.gridTitle}>
									<img src={`${window.config.serviceManagementBackendUrl}/${file.relativePath}`} alt={file.name} />
									<GridListTileBar
										title={file.name}
										actionIcon={
											<IconButton
												aria-label={`info about ${file.title}`}
												className={classes.iconImage}
												target='_blank'
												component='a'
												href={`${window.config.serviceManagementBackendUrl}/${file.relativePath}`}
											>
												<ViewIcon className={classes.iconImage} />
											</IconButton>
										}
									/>
								</GridListTile>
							))}
						</GridList>
					</div>
				)}

				{Attachments.length !== 0 ? (
					Attachments.map((file) => {
						return <UploadFileChip key={file.name} file={file} canBeEdit={canBeEdit} onRemoveFile={onRemoveAttachments} />
					})
				) : (
					<Alert severity='info'>{'There are no files or images added in service'}</Alert>
				)}
			</Grid>
			<br />
			<br />
		</Grid>
	)
}
ImagesComponent.propTypes = {
	onAddAttachments: propTypes.func,
	onRemoveAttachments: propTypes.func
}
ImagesComponent.defaultProps = {
	onAddAttachments: () => console.warn('Callback [onAddAttachments] no defined'),
	onRemoveAttachments: () => console.warn('Callback [onRemoveAttachments] no defined'),
	canBeEdit: false,
	Attachments: []
}

/**
 * Component to upload file
 * @param {*} props
 */
const UploadFileChip = (props) => {
	const { canBeEdit, file, onRemoveFile } = props

	return (
		<>
			{canBeEdit ? (
				<Chip
					color='primary'
					icon={<AttachIcon />}
					label={
						<div style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
							<Typography variant='caption' noWrap title={file.name}>
								{file.name}
							</Typography>
						</div>
					}
					id={file.name}
					key={file.name}
					onDelete={() => {
						onRemoveFile(file.name)
					}}
					deleteIcon={<CloseIcon id={file.name} />}
					style={{ margin: '1px', maxWidth: '450px', overflow: 'hidden', textOverflow: 'ellipsis' }}
				/>
			) : (
				<Chip
					color='primary'
					icon={<AttachIcon />}
					label={
						<div style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
							<Typography variant='caption' noWrap title={file.name}>
								{file.name}
							</Typography>
						</div>
					}
					clickable
					component='a'
					target='_blank'
					href={`${window.config.serviceManagementBackendUrl}/${file.relativePath}`}
					id={file.name}
					key={file.name}
					style={{ margin: '1px', maxWidth: '450px', overflow: 'hidden', textOverflow: 'ellipsis' }}
				/>
			)}
		</>
	)
}

export default ImagesComponent
