/**Import react section */
import React, { useState, useEffect } from 'react'
import propTypes from 'prop-types'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

/**Import components section */
import SideBar from '../../common/DashboadSideBar'
import EvidenceComponent from './EvidencesComponents'
import { Layout, Content, Header, HeaderBreadCrum } from '../../layouts/AuthenticatedLayout'
import { useCreateDesktopStyle } from '../resources/useStyles'
import iconSparePart from '../../../resources/images/sparePart.svg'
import Toolbar from '../../common/Toolbar'
import AlertComponent from '../../common/AlertComponent'
import OpenDesktopViewSkeleton from '../../skeletons/spareParts/OpenDesktopViewSkeleton'
import HeaderOpen from './manual/HeaderOpen'
import EquipmentsList from '../../services/components/EquipmentsList'
import AddressComponent from './Address'
import EquipmentSparePartList from './EquipmentSparePartList'
import EquipmentsSap from '../components/manual/EquipmentsSap'
import Authorization from '../../common/AuthorizationEvaluation'
import AddSparePartPanel from './SparePartPanel'

/**Import Material Ui section section */
import { Typography, Paper, Card, CardContent, Chip, Tabs, Tab, AppBar } from '@material-ui/core'

/**Import store section */
import { hasAuthorization } from '../../../store/session/sessionActions'

/**Import resources sectio */
import {
	IMBERA_TECHNICIAN,
	CUSTOMER_MANAGER,
	CUSTOMER_TECHNICIAN,
	SERVICE_PROVIDER_TECHNICIAN,
	SERVICE_PROVIDER_MANAGER
} from '../../../resources/RolesEnum'
import { GetWarrantyType } from '../../../store/helpers/SelectOptions'
import { SpareParRequestStatus, WarrantyType } from '../../../store/helpers/AppConstants'

const OpenManualDesktopView = (props) => {
	const classes = useCreateDesktopStyle()

	/**Functions props section */
	const {
		onButtonSerieClick,
		onAddSparePart,
		onUpdateEquipment,
		isDownloadReport,
		onUpdateQuantityEquipment,
		onChangeQuerySap,
		onDonwloadReportBySerie,
		onAddFilesInSparePartRequest,
		onRemoveFilesInSparePartRequests,
		sparePartsOptions
	} = props

	const { userProfile, sparePartRequest } = props
	const { isLoading } = props
	/**array props section */
	const { sparePartsSap } = props
	/**string props */
	const { querySparePart } = props

	let isAuthorizadoForEditedData = hasAuthorization(userProfile, [
		IMBERA_TECHNICIAN,
		CUSTOMER_MANAGER,
		CUSTOMER_TECHNICIAN,
		SERVICE_PROVIDER_TECHNICIAN,
		SERVICE_PROVIDER_MANAGER
	])
	const [mode] = useState('MODE_CREATE')
	const [status, setStatus] = useState(null)
	const [visiblePanelIndex, setVisiblePanelIndex] = useState(0)
	const [identifierExpanded, setIdentifierExpanded] = useState([])

	const [lateralPanelState, setLateralPanelState] = useState({
		open: false,
		equipment: null
	})

	useEffect(() => {
		if (!!props.sparePartRequest.open && props.sparePartRequest.open.Status) {
			setStatus(props.sparePartRequest.open.Status)
		}
	}, [props.sparePartRequest.open])

	const onButtonAddClick = (equipment, sparePart) => {
		if (onAddSparePart) {
			onAddSparePart(sparePartRequest.open, equipment, sparePart)
		}
	}

	const onAddSparePartRequest = (equipment) => {
		setLateralPanelState({ open: true, equipment })
	}

	const onAddEvidencesFiles = (files) => {
		if (files.length > 0 && onAddFilesInSparePartRequest) {
			onAddFilesInSparePartRequest(files, sparePartRequest.open)
		}
	}

	/**
	 * Remove file added of state
	 * @param {*} fileId
	 */
	const onRemoveFile = (fileName) => {
		if (fileName && onRemoveFilesInSparePartRequests) {
			onRemoveFilesInSparePartRequests(fileName, sparePartRequest.open)
		}
	}

	const getWarrantyType = (value) => {
		let existWarranty = GetWarrantyType().find((x) => x.value === value)
		if (existWarranty) return existWarranty.label
		return null
	}

	const onRenderConstentRight = () => {
		return (
			<div style={{ display: 'flex', alignSelf: 'center' }}>
				{
					<Authorization
						profile={userProfile}
						allowedRoles={[
							IMBERA_TECHNICIAN,
							CUSTOMER_MANAGER,
							CUSTOMER_TECHNICIAN,
							SERVICE_PROVIDER_TECHNICIAN,
							SERVICE_PROVIDER_MANAGER
						]}
					>
						{!!props.sparePartRequest.open && props.sparePartRequest.open && (
							<Chip
								label='CHANGE STATUS'
								clickable
								color='primary'
								onClick={() => props.openLateralPanelChangeStatus(props.sparePartRequest.open)}
							/>
						)}
					</Authorization>
				}
			</div>
		)
	}

	const renderContent = () => {
		if (isLoading) {
			return <OpenDesktopViewSkeleton />
		} else {
			return (
				<>
					<Toolbar
						title={
							sparePartRequest && sparePartRequest.open
								? `SPARE PART REQUEST | FOLIO: ${sparePartRequest.open.Folio} | Warranty type: ${getWarrantyType(
										sparePartRequest.open.WarrantyType
								  )}`
								: ''
						}
						icon={iconSparePart}
						renderContentRight={onRenderConstentRight()}
					/>
					<HeaderOpen
						onButtonSerieClick={onButtonSerieClick}
						sparePartRequest={sparePartRequest.open ? sparePartRequest.open : null}
						onAddManual={onAddSparePartRequest}
					/>
					<br />
					<Paper>
						{props.sparePartRequest.equipments && props.sparePartRequest.equipments.length !== 0 ? (
							<>
								<EquipmentsList
									equipments={props.sparePartRequest.equipments}
									isAuthorizadoForEditedData={
										sparePartRequest && sparePartRequest.open && isAuthorizadoForEditedData && !sparePartRequest.open.IsManual
									}
									onUpdateSerieInService={
										sparePartRequest && sparePartRequest.open && sparePartRequest.open.IsManual ? onUpdateEquipment : null
									}
									modeView='SPARE_PART_REQUETS_SHOW'
									isWarrantySales={
										props.sparePartRequest &&
										props.sparePartRequest.open &&
										props.sparePartRequest.open.WarrantyType === WarrantyType.SALE
									}
									expandablePanelContent={
										<EquipmentSparePartList
											onRemoveSparePart={props.onRemoveSparePart}
											isSaving={props.sparePartRequest.isSaving}
											identifierSparePartDelete={props.identifierSparePartDelete}
											isAuthorizadoForEditedData={isAuthorizadoForEditedData}
											userProfile={userProfile}
											onUpdateSpartPart={props.onUpdateSpartPart}
											status={status}
											mode={mode}
											isSparePartManual={true}
											identifierExpanded={identifierExpanded}
											setIdentifierExpanded={setIdentifierExpanded}
										/>
									}
									handleClick={handleClick}
									open={open}
								/>
								<br />
							</>
						) : (
							<AlertComponent
								title={'Information'}
								message={'There are no series added for this request please add a series'}
								severityType={'info'}
							/>
						)}

						{/**Render list equipments with spare parts to get from sap */}
						<EquipmentsSap
							equipmentsSap={props.equipmentsSap}
							onAddSparePart={onButtonAddClick}
							onUpdateQuantityEquipment={onUpdateQuantityEquipment}
							onChangeQuerySap={onChangeQuerySap}
							sparePartsSap={sparePartsSap}
							querySparePart={querySparePart}
							isDownloadReport={isDownloadReport}
							onDonwloadReportBySerie={onDonwloadReportBySerie}
							isModeCreate={true}
							onAddManual={onAddSparePartRequest}
							userProfile={userProfile}
						/>
					</Paper>
					<br />
					<Card>
						<AppBar position='static'>
							<Tabs value={visiblePanelIndex} onChange={(event, index) => setVisiblePanelIndex(index)}>
								<Tab label='Address' />
								<Tab label='Files' />
							</Tabs>
						</AppBar>

						<TabPanel value={visiblePanelIndex} index={0}>
							<CardContent>
								<AddressComponent
									onSaveSparePartRequest={props.onSaveSparePartRequest}
									requestDraft={props.sparePartRequest.open}
									isSaving={props.sparePartRequest.isSaving}
									isAuthorizadoForEditedData={isAuthorizadoForEditedData}
									isVisibleChipsAssignement={false}
								/>
							</CardContent>
						</TabPanel>
						<TabPanel value={visiblePanelIndex} index={1}>
							<CardContent>
								<EvidenceComponent
									onAddFiles={onAddEvidencesFiles}
									onRemoveFile={onRemoveFile}
									canBeAddFiles={isAuthorizadoForEditedData && status !== SpareParRequestStatus.CLOSED}
									files={props.sparePartRequest.open && props.sparePartRequest.open.Files ? props.sparePartRequest.open.Files : []}
								/>
							</CardContent>
						</TabPanel>
					</Card>

					{lateralPanelState.open && (
						<AddSparePartPanel
							{...lateralPanelState}
							title='ADD SPARE PART'
							onClose={() => setLateralPanelState({ open: false })}
							onAddSparePart={onButtonAddClick}
							isSaving={props.sparePartRequest.isSaving}
							options={sparePartsOptions}
						/>
					)}
				</>
			)
		}
	}
	const [open, setOpen] = useState(false)
	const handleClick = () => {
		setOpen(!open)
	}

	return (
		<Layout>
			<Header
				key={'AuthenticatedHeader'}
				breadcrumbs={
					<HeaderBreadCrum>
						<Typography color='secondary'>Spare Part Request / View Request</Typography>
					</HeaderBreadCrum>
				}
			/>
			<SideBar key={'AuthenticatedSideBar'} onChangeLocation={props.onChangeLocation} selectedButtonIndex={3} userProfile={userProfile} />
			<Content key={'AuthenticatedContent'}>
				<div className={classes.root}>{renderContent()}</div>
			</Content>
		</Layout>
	)
}

/**
 * A tab panel component
 */
const TabPanel = (props) => {
	const { children, value, index, ...other } = props

	return (
		<div role='tabpanel' hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
			{value === index && children}
		</div>
	)
}
TabPanel.propTypes = {
	children: propTypes.node,
	index: propTypes.any.isRequired,
	value: propTypes.any.isRequired
}

OpenManualDesktopView.propTypes = {
	/**Determinate if get information from backend */
	isLoading: propTypes.bool.isRequired,
	/**Function invoke callback for open lateral panel searh equipments*/
	onButtonSerieClick: propTypes.func.isRequired,
	/**Function invoke callback for add spart part to requests*/
	onAddSparePart: propTypes.func.isRequired,
	/**Function invoke callback for update quatity in redux store */
	onUpdateQuantityEquipment: propTypes.func.isRequired,
	/**Determinate user logged */
	userProfile: propTypes.object.isRequired,
	/**Spart Part request */
	sparePartRequest: propTypes.object.isRequired,
	/**
	 * Download report by serie for spare part
	 */
	onDonwloadReportBySerie: propTypes.func.isRequired,
	/**
	 * Determinate if is download report by serie for spare parts
	 */
	isDownloadReport: propTypes.bool
}
OpenManualDesktopView.defaultProps = {
	isLoading: false,
	isDownloadReport: false,
	onButtonSerieClick: () => console.warn('Callback [onButtonSerieClick] no defined'),
	onAddSparePart: () => console.warn('Callback [onAddSparePart] no defined'),
	onUpdateQuantityEquipment: () => console.warn('Callback [onUpdateQuantityEquipment] no defined'),
	userProfile: null,
	sparePartRequest: null,
	onDonwloadReportBySerie: () => console.warn('Callback [onDonwloadReportBySerie] no defined')
}
export default withImmutablePropsToJS(OpenManualDesktopView)
