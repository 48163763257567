import axios from 'axios'
import { getAuthorizedConfiguration } from '../../store/helpers/RequestHelper'

export const EXECUTING_LOGOUT = 'EXECUTING_LOGOUT'
export const IS_GETTING_PROFILE = 'IS_GETTING_PROFILE'
export const SET_USER_PROFILE = 'SET_USER_PROFILE'

/**
 * Logout action constructor
 */
export const executeLogout = () => {
	return {
		type: EXECUTING_LOGOUT
	}
}

/**
 * Is getting profile action constructor
 */
const isGettingProfile = (value) => {
	return {
		type: IS_GETTING_PROFILE,
		value: value
	}
}

/**
 * Set user profile action constructor
 */
const setUserProfile = (profile) => {
	return {
		type: SET_USER_PROFILE,
		profile
	}
}

/**
 * Determine if a user has a role base authorization
 */
export const hasAuthorization = (userProfile, allowedRoles = []) => {
	if (!userProfile) return false

	if (allowedRoles.length === 0) return true

	return allowedRoles.reduce((value, allowedRole) => {
		if (value === true) return true
		let foundRole = userProfile.roles.find((x) => x.ClaimValue === allowedRole)
		return foundRole ? true : false
	}, false)
}

/**
 * Load the user profile in the imbera usa app
 */
export const loadUserProfile = () => {
	return async function(dispatch, getState) {
		var identityUser = getState().oidc.user
		var profile = getState().session.get('profile')

		var isUserLogged = () => identityUser
		var pendingAskForProfile = () => profile == null

		if (isUserLogged && pendingAskForProfile) {
			try {
				const { identityServerUrl, serviceManagementBackendUrl } = window.config
				dispatch(isGettingProfile(true))

				// Get the user profile from the identity server
				let urlIdentityServer = `${identityServerUrl}/api/user/userName/${identityUser.profile.preferred_username}`
				let identityProfileResponse = await axios.get(urlIdentityServer)

				let urlIdentityServerAvatar = `${identityServerUrl}/api/user/${identityProfileResponse.data.id}`
				let identytyResponseDataAvatar = await axios.get(urlIdentityServerAvatar, null)

				// Get the user profile from the user management backend
				const headerRequest = getAuthorizedConfiguration(identityUser)
				let backendServerUrl = `${serviceManagementBackendUrl}/user/profile`
				let backendResponse = await axios.get(backendServerUrl, headerRequest)
				// Create the full user profile with the information of identity server
				// and the roles of service management backen
				let fullUserProfile = {
					userName: identityUser.profile.preferred_username,
					userType: identityUser.profile.user_type,
					name: identityProfileResponse.data.full_name,
					email: identityProfileResponse.data.email,
					avatarUrl: identytyResponseDataAvatar.data.avatar_url,
					enabled: identityProfileResponse.data.enabled,
					roles: backendResponse.data.Roles ? backendResponse.data.Roles : [],
					Account: backendResponse.data.Account ? backendResponse.data.Account : null,
					Token: backendResponse.data.Token ? backendResponse.data.Token : ''
				}
				dispatch(setUserProfile(fullUserProfile))
				return Promise.resolve(fullUserProfile)
			} catch (error) {
				console.error("Error getting the user's profile", error)
			}
		} else {
			return Promise.reject('No logged user found in the store')
		}
	}
}
