/**Import react section */
import React, { useState, useEffect } from 'react'
import propTypes from 'prop-types'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

/**Import styles section */
import { PositionMapDefault } from '../../../store/helpers/AppConstants'
import { GetBackgroundColorServiceByStatus } from '../../../store/helpers/StatusHelper'

/**Import Material Ui section */
import { Card, Grid, Typography, CardContent, Chip, Paper, Divider } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import LocationOnIcon from '@material-ui/icons/LocationOnOutlined'

/**Import components section */
import iconFailureDoor from '../../../resources/images/failureWarranty/door.svg'
import { Layout, Content, Header, HeaderBreadCrum } from '../../layouts/AuthenticatedLayout'
import SideBar from '../../common/DashboadSideBar'
import { showMobileStyles } from '../resources/ShowMobileViewStyle'
import DateAttentionComponent from '../components/DateAttentionComponent'
import ShowMobileSkeleton from '../../skeletons/services/componets/ShowMobileSkeleton'
import Authorization from '../../common/AuthorizationEvaluation'
import MapComponent from '../components/MapComponent'
import ImagesComponent from '../componentsMobile/ImagesComponent'

/**Import resources section */
import { hasAuthorization } from '../../../store/session/sessionActions'
import { DISPATCHER, IMBERA_TECHNICIAN, SERVICE_PROVIDER_TECHNICIAN, SERVICE_PROVIDER_MANAGER } from '../../../resources/RolesEnum'
import ExpandedSummary from './ExpandedSummary'
import SerieComponent from '../componentsMobile/SerieComponent'
import Toolbar from '../../common/Toolbar'

const ShowMobilView = (props) => {
	const {
		service,
		isSavingSeries,
		onAddSeries,
		onUpdateSerieInService,
		isLoading,
		onShowWarranty,
		userProfile,
		isSavingService,
		onSaveService,
		openLateralPanelChangeStatus,
		openLateralPanelAssignedProvider,
		onAddDateAttention,
		onAddSpareRequestInDraft,
		isSavingRequestDraft,
		onAddAttachments,
		onRemoveAttachments
	} = props
	const [serviceModel, setServiceModel] = useState(service)
	const [serviceProvider, setServiceProvider] = useState(null)

	let canBeAddTechProvider = hasAuthorization(userProfile, [SERVICE_PROVIDER_MANAGER])
	let isAuthorizerCanEditDateAttention = hasAuthorization(userProfile, [SERVICE_PROVIDER_TECHNICIAN, SERVICE_PROVIDER_MANAGER])
	let canBeEditDateAttention = isAuthorizerCanEditDateAttention && (serviceModel ? !serviceModel.IsClosed : false)

	const colorByStatus = GetBackgroundColorServiceByStatus(service ? service.Status : null)

	useEffect(() => {
		if (service) {
			if (service && service.ServiceProvider && serviceProvider === null) {
				let objectMapped = {
					value: service.ServiceProvider.Code,
					label: service.ServiceProvider.Name
				}
				setServiceProvider(objectMapped)
			}
			setServiceModel(service)
		}
	}, [service, serviceProvider])

	const classes = showMobileStyles()

	const onRenderViewService = () => {
		let isActiveButtonStatus = !isLoading && (service ? !service.IsClosed : false)
		return (
			<div style={{ display: 'flex', alignSelf: 'center' }}>
				{isActiveButtonStatus ? (
					<Authorization profile={userProfile} allowedRoles={[DISPATCHER, IMBERA_TECHNICIAN]}>
						<Chip
							label='CHANGE STATUS'
							clickable
							color='primary'
							disabled={isLoading}
							onClick={() => openLateralPanelChangeStatus(service)}
						/>
					</Authorization>
				) : null}
				<>
					{canBeAddTechProvider && service && service.CanBeAssignedProvider && (
						<Authorization profile={userProfile} allowedRoles={[SERVICE_PROVIDER_MANAGER]}>
							<Chip
								label='ASSIGN TECHNICIAN'
								clickable
								color='primary'
								disabled={isLoading}
								onClick={() => openLateralPanelAssignedProvider(service)}
							/>
						</Authorization>
					)}
					{!canBeAddTechProvider && service && service.CanBeCloseTechProvider && (
						<Authorization profile={userProfile} allowedRoles={[SERVICE_PROVIDER_TECHNICIAN]}>
							<Chip
								label='CLOSE SERVICE'
								clickable
								color='primary'
								disabled={isLoading}
								onClick={() => openLateralPanelAssignedProvider(service)}
							/>
						</Authorization>
					)}
				</>
			</div>
		)
	}

	/**
	 * function on get address from service
	 */
	const getAddress = () => {
		if (service && service.Address !== null) {
			return service.Address
		} else
			return {
				route: '',
				street_number: '',
				locality: '',
				administrative_area_level_1: '',
				country: '',
				postal_code: '',
				latitude: PositionMapDefault.latitude,
				longitude: PositionMapDefault.longitude,
				full_address: ''
			}
	}

	/** Render Content */
	const onRenderContent = () => {
		if (isLoading) {
			return <ShowMobileSkeleton />
		} else {
			return (
				<div className={classes.root}>
					<Toolbar
						title={`TICKET: ${service && service.Ticket}`}
						renderContentRight={onRenderViewService()}
						renderBackgroundColor={colorByStatus}
						icon={iconFailureDoor}
					/>
					{service && (
						<Card>
							<CardContent>
								<Typography className={classes.textHeader} color='textSecondary'>
									{service.SalesRepresentative ? `REP: ${service.SalesRepresentative.Name}` : ''}
								</Typography>
								<Typography className={classes.textHeader} color='textSecondary'>{`RETAILER: ${
									service.Retailer ? service.Retailer.Name : ''
								}`}</Typography>
								{service.ContactName && (
									<Typography color='textSecondary'>{`Contact name: ${service.ContactName ? service.ContactName : ''}`}</Typography>
								)}
								{service.ContactPhone && (
									<Typography color='textSecondary'>{`Contact phone: ${
										service.ContactPhone ? service.ContactPhone : ''
									}`}</Typography>
								)}
								{service.SiteContactName && (
									<Typography color='textSecondary'>{`Site contact name: ${
										service.SiteContactName ? service.SiteContactName : ''
									}`}</Typography>
								)}
								{service.SiteContactPhone && (
									<Typography color='textSecondary'>{`Site contact phone: ${
										service.SiteContactPhone ? service.SiteContactPhone : ''
									}`}</Typography>
								)}
							</CardContent>
						</Card>
					)}

					<Paper>
						<div className={classes.root}>
							<Expanded
								service={service ? service : undefined}
								onShowWarranty={onShowWarranty}
								onAddSeries={onAddSeries}
								onUpdateSerieInService={onUpdateSerieInService}
								isSavingSeries={isSavingSeries}
								isSavingService={isSavingService}
								isLoading={isLoading}
								onAddDateAttention={onAddDateAttention}
								canBeEditDateAttention={canBeEditDateAttention}
								onSaveService={onSaveService}
								onAddSpareRequestInDraft={onAddSpareRequestInDraft}
								isSavingRequestDraft={isSavingRequestDraft}
								onAddAttachments={onAddAttachments}
								onRemoveAttachments={onRemoveAttachments}
							/>
						</div>
					</Paper>

					<AddressComponent address={getAddress()} />
				</div>
			)
		}
	}

	return (
		<Layout>
			<Header
				key={'AuthenticatedHeader'}
				user={props.user}
				breadcrumbs={
					<HeaderBreadCrum>
						<Typography color='secondary'>Service</Typography>
					</HeaderBreadCrum>
				}
			/>
			<SideBar key={'AuthenticatedSideBar'} onChangeLocation={props.onChangeLocation} selectedButtonIndex={1} userProfile={userProfile} />
			<Content key={'AuthenticatedContent'}>{onRenderContent()}</Content>
		</Layout>
	)
}
ShowMobilView.propTypes = {
	service: propTypes.shape({
		Ticket: propTypes.string.isRequired
	}),
	salesRepresentative: propTypes.shape({
		Name: propTypes.string.isRequired
	}),
	retailer: propTypes.shape({
		Name: propTypes.string.isRequired
	}),
	address: propTypes.shape({
		route: propTypes.string.isRequired,
		street_number: propTypes.string.isRequired,
		locality: propTypes.string.isRequired,
		administrative_area_level_1: propTypes.string.isRequired,
		country: propTypes.string.isRequired,
		postal_code: propTypes.string.isRequired,
		latitude: propTypes.number.isRequired,
		longitude: propTypes.number.isRequired,
		full_address: propTypes.string.isRequired
	})
}
ShowMobilView.defaultProps = {
	service: {
		Ticket: ''
	},
	salesRepresentative: {
		Name: ''
	},
	retailer: {
		Name: ''
	},
	address: {
		route: '',
		street_number: '',
		locality: '',
		administrative_area_level_1: '',
		country: '',
		postal_code: '',
		latitude: PositionMapDefault.latitude,
		longitude: PositionMapDefault.longitude,
		full_address: ''
	}
}

/**
 * Component expanded service
 * @param {*} props
 */
const Expanded = (props) => {
	const classes = showMobileStyles()
	const { service } = props
	const { onAddSeries, onUpdateSerieInService, onAddDateAttention, onAddSpareRequestInDraft, onAddAttachments, onRemoveAttachments } = props
	const { isSavingSeries, isSavingService, isLoading, canBeEditDateAttention, isSavingRequestDraft } = props

	const onRenderCommnets = () => {
		return (
			<SnackbarContent
				className={classes.contenrSnack}
				message={`${service ? (service.ServiceDescription ? service.ServiceDescription : '') : ''}`}
			/>
		)
	}

	const onRenderContentDateAttention = () => {
		return (
			<DateAttentionComponent
				service={service ? service : null}
				onAddDateAttention={onAddDateAttention}
				isSavingService={isSavingService}
				isAuthorizadoForEditedData={canBeEditDateAttention}
			/>
		)
	}

	const onRenderContentImanges = () => {
		let isActiveButtonStatus = service && !service.IsClosed
		return (
			<ImagesComponent
				Attachments={service ? service.Attachments : []}
				onAddAttachments={onAddAttachments}
				onRemoveAttachments={onRemoveAttachments}
				canBeEdit={isActiveButtonStatus || canBeEditDateAttention}
			/>
		)
	}

	return (
		<>
			<Typography className={classes.titleSeries}>{'SERIES'}</Typography>
			<Divider />
			<SerieComponent
				equipments={service ? service.Equipments : []}
				isSavingSeries={isSavingSeries}
				isLoading={isLoading}
				onAddSeries={onAddSeries}
				onUpdateSerieInService={onUpdateSerieInService}
				isAuthorizadoForEditedData={canBeEditDateAttention}
				onAddSpareRequestInDraft={onAddSpareRequestInDraft}
				isSavingRequestDraft={isSavingRequestDraft}
			/>

			{service && service.ServiceDescription && (
				<ExpandedSummary id='item2' title='Comments' onRenderContent={onRenderCommnets} isPaddingContent={false} isVisibleColor={true} />
			)}

			<ExpandedSummary
				id='item3'
				title='Evidence or images'
				onRenderContent={onRenderContentImanges}
				isPaddingContent={true}
				isExpanded={false}
				isVisibleColor={true}
			/>

			<ExpandedSummary
				id='item3'
				title='Date attention'
				onRenderContent={onRenderContentDateAttention}
				isPaddingContent={true}
				isExpanded={false}
				isVisibleColor={true}
			/>
		</>
	)
}
Expanded.propTypes = {
	service: propTypes.object
}

/**
 * Component address show
 * @param {*} props
 */
const AddressComponent = (props) => {
	const { address } = props

	return (
		<Card>
			<CardContent>
				<Grid item xs={12}>
					<Typography variant='subtitle1'>
						<strong>ADDRESS</strong>
					</Typography>
				</Grid>
				<MapComponent position={{ latitude: address.latitude, longitude: address.longitude }} fullAddress={address.full_address} />
				<br />
				<Alert variant='outlined' severity='info' icon={<LocationOnIcon fontSize='inherit' />}>
					<AlertTitle>Address</AlertTitle>
					{`${address.street_number} ${address.route}`} <br />
					{`${address.locality}, ${address.administrative_area_level_1}, ${address.country}`}
					<br />
					{`${address.postal_code}`}
				</Alert>
			</CardContent>
		</Card>
	)
}
AddressComponent.propTypes = {
	address: propTypes.object.isRequired
}

export default withImmutablePropsToJS(ShowMobilView)
