/**
 * Define the create services styles
 *
 */
import { makeStyles } from '@material-ui/styles'

/**
 *
 */
export const createPaymentStyle = makeStyles((theme) => ({
	root: {
		maxWidth: theme.views.maxWidth,
		marginLeft: 'auto',
		marginRight: 'auto',
		marginTop: '10px',
		maxHeight: `calc(100vh - ${85}px)`,
		overflowY: 'auto'
	},
	container: {
		padding: '5px'
	},
	avatarColor: {
		color: '#6C757D',
		backgroundColor: '#EEEEEE'
	},
	grow: {
		flexGrow: 1
	},
	headerContainer: {
		display: 'flex',
		marginTop: '5px',
		marginBottom: '5px'
	},
	textHeader: {
		marginLeft: '10px',
		marginTop: '10px'
	},
	buttonNext: {
		margin: theme.spacing(1)
	},
	buttonAdd: {
		display: 'flex',
		justifyContent: 'flex-end',
		marginTop: '10px'
	},
	locationLogo: {
		width: '100%',
		height: '40%'
	},
	table: {
		overflowY: 'auto'
	},
	tableEquipment: {
		minHeight: '160px',
		overflowY: 'auto'
	},
	fabStyle: {
		marginRight: theme.spacing(0.5),
		width: theme.spacing(4),
		height: theme.spacing(4),
		backgroundColor: '#bdbdbd'
	},
	containerFailureButtons: {
		display: 'flex',
		direction: 'row'
	},
	faultWarrantyIconSelected: {
		marginRight: theme.spacing(0.5),
		width: theme.spacing(4),
		height: theme.spacing(4)
	},
	title: {
		color: theme.palette.primary.main,
		fontSize: '0.9rem',
		fontWeight: 'bold'
	},
	updateButton: {
		display: 'flex',
		justifyContent: 'flex-end'
	},
	button: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		gap: '10px',
		// padding: '12px 24px',
		fontSize: '12px',
		fontWeight: 'bold',
		textTransform: 'none',
		backgroundColor: theme.palette.serviceStatus.newServiceDark,
		color: '#fff',
		marginLeft: '3px',
		borderRadius: '30px',
		transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
		'&:hover': {
			transform: 'translateX(5px)',
			boxShadow: '5px 5px 15px rgba(0, 0, 0, 0.3)'
		}
	},
	pulseAnimation: {
		animation: `$pulse 1.5s infinite ease-in-out`
	},
	'@keyframes pulse': {
		'0%': {
			transform: 'scale(1)'
		},
		'50%': {
			transform: 'scale(1.05)'
		},
		'100%': {
			transform: 'scale(1)'
		}
	}
}))
