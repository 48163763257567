import { makeStyles } from '@material-ui/styles'

/**
 * One column view layout
 */
export const oneColumnStyles = makeStyles((theme) => ({
	root: {
		height: '100vh',
		display: 'grid',
		gridTemplateRows: '64px auto',
		backgroundColor: theme.palette.secondary.main
	},
	header: {
		gridRow: '1/2'
	},
	content: {
		gridRow: '2/3'
	},
	appLogo: {
		height: 40
	}
}))

/**
 * Two columns view layout
 */
export const twoColumnsStyle = makeStyles((theme) => ({
	root: {
		height: '100vh',
		display: 'grid',
		gridTemplateRows: '64px auto',
		gridTemplateColumns: 'auto 1fr',
		backgroundColor: theme.palette.common.white
	},
	header: {
		gridColumn: ' 2 / 3',
		gridRow: '1 / 2'
	},
	sideBar: {
		gridColumn: ' 1 / 2',
		gridRow: '1 / 3'
	},
	content: {
		gridColumn: ' 2 / 3',
		gridRow: '2 / 3',
		backgroundColor: theme.palette.colors.grayLayout
	},
	notificationPaper: {
		zIndex: 10
	},
	contentNotifications: {
		overflowY: 'auto',
		overflowX: 'hidden',
		maxHeight: '55vh',
		maxWidth: '390px'
	},
	outlinedRoot: {
		'&:hover': {
			backgroundColor: `${theme.palette.colors.blueLight} !important`,
			color: theme.palette.colors.blue,
			borderColor: theme.palette.colors.blue
		}
	},
	rootTitle: {
		width: '100%',
		color: `${theme.palette.colors.primaryDisabled} !important`,
		backgroundColor: `${theme.palette.colors.grayLayout} !important`
	}
}))
