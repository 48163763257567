/**Import react sections */
import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom'

import DesktopView from './components/DesktopView'
import Toaster from '../common/Toaster'
import Edit from './Edit'
import WarrantyChecksPanel from '../services/components/WarrantyChecksPanel'
import LateralPanel from '../common/lateralPanel/LateralPanel'

/**Import actions section */
import {
	getPaymentRequest,
	addServiceToPayment,
	updateAmountInServiceInPayment,
	addFilesBySupplier,
	removeFilesProvider,
	addFilesByPlant,
	removeFilesPlant,
	loadPaymentRequests
} from '../../store/paymentrequests/paymentRequestActions'
import { loadServicesClosed, updateCommentsServiceChange, updateSubCategoryInSerivce } from '../../store/services/serviceActions'
import { servicesClosedSelector } from '../../store/services/servicesSelectors'
import { toSafeObject } from '../../store/helpers/StateHelper'
import { isNullOrEmpty } from '../../store/helpers/StringHelper'

/**
 * Open sparte parts view
 */
const Show = (props) => {
	/**Section hooks */
	const history = useHistory()
	let match = useRouteMatch()
	const dispatch = useDispatch()
	const userProfile = useSelector((state) => toSafeObject(state.session.get('profile')))
	const paymentRequest = useSelector((state) => toSafeObject(state.paymentRequests.get('paymentRequest')))
	const isLoadingRequest = useSelector((state) => state.paymentRequests.get('isLoadingRequest'))
	const isSavingService = useSelector((state) => state.paymentRequests.get('isSavingService'))
	const paymentPartsIndex = useSelector((state) => toSafeObject(state.paymentRequests.get('index')))

	const token = match.params.token
	const services = useSelector((state) => servicesClosedSelector(state))
	const [lateralPanelStatusConfig, setLateralPanelStatusConfig] = useState({ opened: false, payment: null })
	const [lateralPanelEquipment, setLateralPanelEquipment] = useState({ opened: false, equipment: null })
	const [dialogPasswordConfig, setDialogPasswordConfig] = useState({ opened: false, item: null, identifier: '' })

	const [toaster, setToaster] = useState({
		open: false,
		variant: null,
		message: null
	})

	useEffect(() => {
		dispatch(getPaymentRequest(token))
	}, [dispatch, token])

	useEffect(() => {
		const queryParameters = {
			statusFilter: ['2'],
			sortBy: 'CreationDate',
			sortDirection: 'asc'
		}

		dispatch(loadPaymentRequests(queryParameters, 100)).catch(() => {})
	}, [dispatch])

	useEffect(() => {
		dispatch(loadServicesClosed())
	}, [dispatch])

	/**
	 * Add service in payment
	 * @param {*} service
	 */
	const addServiceInPayment = (service) => {
		return dispatch(addServiceToPayment(token, service)).catch((error) => {
			setToaster({ open: true, message: !isNullOrEmpty(error) ? error : 'An error to add service in payment', variant: 'error' })
		})
	}

	/**
	 * Function to update amount item in service
	 * @param {*} sparePart
	 */
	const onUpdateAmountInService = (serviceToken, amountToken, amountValue) => {
		return dispatch(updateAmountInServiceInPayment(serviceToken, amountToken, amountValue)).catch((error) => {
			setToaster({
				open: true,
				message: !isNullOrEmpty(error) ? error : 'An error occurred while updating your amount in service',
				variant: 'warning'
			})
		})
	}

	const onAddFilesBySupplier = (files) => {
		return dispatch(addFilesBySupplier(token, files)).catch((error) => {
			setToaster({ open: true, message: !isNullOrEmpty(error) ? error : 'An error occurred while adding file', variant: 'warning' })
		})
	}

	const onRemoveFilesSupplier = (fileName) => {
		return dispatch(removeFilesProvider(token, fileName)).catch((error) => {
			setToaster({ open: true, message: !isNullOrEmpty(error) ? error : 'An error occurred while remove file', variant: 'warning' })
		})
	}

	const onAddFilesByPlant = (files) => {
		return dispatch(addFilesByPlant(token, files)).catch((error) => {
			setToaster({ open: true, message: !isNullOrEmpty(error) ? error : 'An error occurred while adding file', variant: 'warning' })
		})
	}

	const onRemoveFilesInPlant = (fileName) => {
		return dispatch(removeFilesPlant(token, fileName)).catch((error) => {
			setToaster({ open: true, message: !isNullOrEmpty(error) ? error : 'An error occurred remove file', variant: 'warning' })
		})
	}

	const onCommentsServiceChange = (service, comments) => {
		return dispatch(updateCommentsServiceChange(service, comments)).catch((error) => {
			setToaster({
				open: true,
				message: !isNullOrEmpty(error) ? error : 'An error occurred while updating your amount in service',
				variant: 'warning'
			})
		})
	}

	/**
	 * Function to update equipment ih the service
	 * @param {*} equipmentToken
	 * @param {*} property
	 * @param {*} propertyData
	 */
	const onUpdateSerieInService = (
		service,
		equipmentToken,
		refrigerationNotCoolFailure,
		iluminationFailure,
		doorFailure,
		comments,
		faultWarranty
	) => {
		return dispatch(
			updateSubCategoryInSerivce(
				service.Token,
				equipmentToken,
				refrigerationNotCoolFailure,
				iluminationFailure,
				doorFailure,
				comments,
				faultWarranty
			)
		)
			.then(() => {
				dispatch(getPaymentRequest(token))
			})
			.catch(() => {
				console.error('Error to update service')
			})
	}

	/**Open lateral panel for show equipment warranties */
	const openLateralPanelChangeStatus = (payment) => {
		setLateralPanelStatusConfig({ opened: !lateralPanelStatusConfig.opened, payment: payment })
	}

	const onChangeLocation = (location) => history.push(location)

	/**Open lateral panel for show equipment warranties */
	const openLateralPanelOnClick = (item) => {
		setLateralPanelEquipment({ opened: !lateralPanelEquipment.opened, equipment: item })
	}

	/**Close lateral panel equipment*/
	const onCloseButtonClick = () => {
		setLateralPanelEquipment({ opened: false, equipment: null })
	}

	/**Open lateral panel for show equipment warranties */
	const openCloseDialogPassword = (item, identifier) => {
		setDialogPasswordConfig({ opened: !dialogPasswordConfig.opened, item: item, identifier: identifier })
	}

	const onRedirectToNextPaymentRequest = () => {
		let allPaymentsFilters = paymentPartsIndex.paymentRequests.filter((x) => x.Token !== token)
		if (allPaymentsFilters && allPaymentsFilters.length !== 0) {
			console.log('paymentPartsIndex.paymentRequests', allPaymentsFilters)
			history.push(`/paymentrequests/${allPaymentsFilters[0].Token}`)
		}
	}

	const onGetPaymentTotal = () => {
		let total = 0
		let allPaymentsFilters = paymentPartsIndex.paymentRequests.filter((x) => x.Token !== token)
		if (allPaymentsFilters && allPaymentsFilters.length !== 0) {
			// total = paymentPartsIndex.pagination.pageSize * paymentPartsIndex.pagination.total
			total = allPaymentsFilters && allPaymentsFilters.length
		}
		return total
	}

	return (
		<Fragment>
			<DesktopView
				onChangeLocation={onChangeLocation}
				isLoading={isLoadingRequest}
				paymentRequest={paymentRequest}
				userProfile={userProfile}
				closedServices={services}
				addServiceInPayment={addServiceInPayment}
				isSavingService={isSavingService}
				onUpdateAmountInService={onUpdateAmountInService}
				onAddFilesBySupplier={onAddFilesBySupplier}
				onRemoveFilesSupplier={onRemoveFilesSupplier}
				openLateralPanelChangeStatus={openLateralPanelChangeStatus}
				onAddFilesByPlant={onAddFilesByPlant}
				onRemoveFilesInPlant={onRemoveFilesInPlant}
				onShowWarranty={openLateralPanelOnClick}
				onCommentsServiceChange={onCommentsServiceChange}
				openCloseDialogPassword={openCloseDialogPassword}
				onUpdateSerieInService={onUpdateSerieInService}
				paymentPartsIndex={paymentPartsIndex}
				onRedirectToNextPaymentRequest={onRedirectToNextPaymentRequest}
				pendingPaymentTotal={onGetPaymentTotal()}
			/>

			<Edit
				lateralPanelStatusConfig={lateralPanelStatusConfig}
				openLateralPanelChangeStatus={openLateralPanelChangeStatus}
				isLoading={isLoadingRequest}
				isSavingPayment={isSavingService}
				userProfile={userProfile}
				openCloseDialogPassword={openCloseDialogPassword}
				dialogPasswordConfig={dialogPasswordConfig}
			/>

			<Toaster toasterOptions={toaster} onClose={() => setToaster({ open: false })} />

			{/** Equipment Panel */
			lateralPanelEquipment.opened && (
				<LateralPanel onClose={onCloseButtonClick}>
					<WarrantyChecksPanel
						onCloseButtonClick={onCloseButtonClick}
						equipment={lateralPanelEquipment.equipment}
						serviceType={lateralPanelEquipment.equipment ? lateralPanelEquipment.equipment.ServiceType : ''}
					/>
				</LateralPanel>
			)}
		</Fragment>
	)
}
export default Show
