/**Import react section */
import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import propTypes from 'prop-types'
import { useForm } from 'react-hook-form'
/**Import Material Ui section */
import { Grid, Typography, Chip } from '@material-ui/core'
/**Import Material UI Section */
import DialogActions from '@material-ui/core/DialogActions'

/**Import styles section */
import { createServicesStyle } from '../../../../resources/styles/CreateServiceStyles'
import { getUsersOptionsByRole } from '../../../../store/helpers/UserHelper'
import { toSafeObject } from '../../../../store/helpers/StateHelper'
import { SERVICE_PROVIDER_TECHNICIAN } from '../../../../resources/RolesEnum'
/**Import components section */
import ButtonConfirm from '../../../common/Buttons/ButtonConfirm'
import ReactSelectAvatar from '../../../common/ReactSelectAvatar'
import AvatarCustomSize from '../../../common/AvatarCustomSizes'

const AssgmentTechServiceProvider = (props) => {
	/**Props section */
	const { isSavingService, isAuthorizadoForEditedData } = props
	const { onAssignementTechServiceProvider } = props

	/**Custom hooks an styles */
	const classes = createServicesStyle()
	const { register, errors, control, handleSubmit } = useForm()
	const [assignementModel, setAssignementModel] = useState({
		serviceProviderTechAssigned: props.serviceProviderTechAssigned
	})

	/**Get option from store redux */
	const providerUsers = useSelector((state) => toSafeObject(state.serviceProviders.get('providerUsers')))
	/**Map the options to show in the selector */
	let onTechProvidersOptions = getUsersOptionsByRole(SERVICE_PROVIDER_TECHNICIAN, providerUsers, false)

	/**Verify if can be sabed */
	const canBeSaved = () => {
		if (assignementModel ? assignementModel.serviceProviderTechAssigned === null : true) return false
		return true
	}

	/** Invoke when select property change*/
	const onSelectChange = (name, option) => {
		setAssignementModel({
			...assignementModel,
			[name]: option
		})
	}

	/**
	 * function to assigned service
	 */
	const assignedService = () => {
		if (onAssignementTechServiceProvider) {
			onAssignementTechServiceProvider(assignementModel)
		}
	}

	return (
		<Grid item xs={12}>
			<Typography className={classes.title}>{'ASSIGN SERVICE PROVIDER TECHNICIAN'}</Typography>
			<ReactSelectAvatar
				id='serviceProviderTechAssigned'
				name='serviceProviderTechAssigned'
				placeholder={'TECHNICIAN PROVIDER'}
				formatOptionLabel={formatOptionLabel}
				options={onTechProvidersOptions}
				autoFocus
				onChange={onSelectChange}
				value={
					assignementModel.serviceProviderTechAssigned && !!assignementModel.serviceProviderTechAssigned.UserName
						? onTechProvidersOptions.find((x) => x.value === assignementModel.serviceProviderTechAssigned.UserName)
						: null
				}
				register={
					assignementModel && !!assignementModel.serviceProviderTechAssigned
						? register({ name: 'serviceProviderTechAssigned' }, { required: { value: false } })
						: register(
								{ name: 'serviceProviderTechAssigned' },
								{
									required: {
										value: false,
										message: 'The serviceProviderTechAssigned is required'
									}
								}
						  )
				}
				errors={errors}
				control={control}
				isDisabled={!isAuthorizadoForEditedData}
			/>{' '}
			<br />
			{isAuthorizadoForEditedData && (
				<DialogActions>
					<ButtonConfirm isSaving={isSavingService || !canBeSaved()} onSavedClick={handleSubmit(assignedService)} title='CONFIRM' />
				</DialogActions>
			)}
		</Grid>
	)
}

const formatOptionLabel = ({ value, label, avatarUrl, isAvatarFromIdentity }) => (
	<div>
		<Chip
			id={value}
			size='small'
			avatar={<AvatarCustomSize avatarUrl={avatarUrl} name={label} isAvatarFromIdentity={isAvatarFromIdentity} />}
			label={label}
			clickable
			style={{ color: 'white', background: '#5357DC' }}
		/>
	</div>
)

AssgmentTechServiceProvider.propTypes = {
	isSavingService: propTypes.bool.isRequired,
	/**Function invoke callback for assigned service */
	onAssignementTechServiceProvider: propTypes.func.isRequired,
	isAuthorizadoForEditedData: propTypes.bool.isRequired
}
AssgmentTechServiceProvider.defaultProps = {
	isSavingService: false,
	isAuthorizadoForEditedData: false,
	onAssignementTechServiceProvider: () => console.warn('Callback [onAssignementTechServiceProvider] no defined')
}

export default AssgmentTechServiceProvider
