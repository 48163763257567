/**Import react section */
import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import propTypes from 'prop-types'
import { useForm } from 'react-hook-form'
/**Import Material Ui section */
import { Grid, Typography, Chip } from '@material-ui/core'
/**Import Material UI Section */
import DialogActions from '@material-ui/core/DialogActions'

/**Import styles section */
import { createServicesStyle } from '../../../../resources/styles/CreateServiceStyles'
import { getOptionsReactSelect, getOptionsPreferential } from '../../../../store/helpers/FilterHelper'
import { getUsersOptionsByRole } from '../../../../store/helpers/UserHelper'
import { toSafeObject } from '../../../../store/helpers/StateHelper'
import { IMBERA_TECHNICIAN, DISPATCHER, SERVICE_PROVIDER_TECHNICIAN } from '../../../../resources/RolesEnum'
/**Import components section */
import ButtonConfirm from '../../../common/Buttons/ButtonConfirm'
import ReactSelectAvatar from '../../../common/ReactSelectAvatar'
import AvatarCustomSize from '../../../common/AvatarCustomSizes'

const AssignementService = (props) => {
	/**Props section */
	const { isSavingService, isAuthorizadoForEditedData, customer } = props
	const { onAssignementService } = props

	/**Custom hooks an styles */
	const classes = createServicesStyle()
	const { register, errors, control, handleSubmit } = useForm()
	const [preferentActive, setPreferentActive] = useState(false)
	const [isNecessary, setIsNecessary] = useState(false)
	const [assignementModel, setAssignementModel] = useState({
		dispatcher: props.dispatcher,
		techAssigned: props.techAssigned,
		serviceProvider: props.serviceProvider,
		serviceProviderTechAssigned: props.serviceProviderTechAssigned
	})

	/**Get option from store redux */
	const internalUsers = useSelector((state) => toSafeObject(state.users.get('internalUsers')))
	const allServiceProviders = useSelector((state) => toSafeObject(state.serviceProviders.get('serviceProviders')))
	const providerUsers = useSelector((state) => toSafeObject(state.serviceProviders.get('providerUsers')))
	const preferentServiceProviders = useSelector((state) => state.serviceProviders.get('preferentServiceProviders'))

	/**Map the options to show in the selector */
	let seviceProviderOptions = getOptionsReactSelect(allServiceProviders)
	let preferentProviderOption = getOptionsPreferential(preferentServiceProviders)
	let onDispatchersOptions = getUsersOptionsByRole(DISPATCHER, internalUsers, false)
	let technicianOptions = getUsersOptionsByRole(IMBERA_TECHNICIAN, internalUsers, false)
	let onTechProvidersOptions = getUsersOptionsByRole(SERVICE_PROVIDER_TECHNICIAN, providerUsers, false)

	/**Verify if can be sabed */
	const canBeSaved = () => {
		if (assignementModel ? assignementModel.techAssigned === null : true) return false
		if (
			assignementModel
				? assignementModel.serviceProvider === null
				: true || assignementModel
				? assignementModel.preferentServiceProvider === null
				: true
		)
			return false
		return true
	}

	/** Invoke when select property change*/
	const onSelectChange = (name, option) => {
		setAssignementModel({
			...assignementModel,
			[name]: option
		})
	}

	/** Invoke when select property change and disable preferent selector*/
	const onSelectProvider = (name, option) => {
		setAssignementModel({
			...assignementModel,
			[name]: option
		})
		setPreferentActive(true)
		setIsNecessary(false)
	}

	/** Invoke when select property change and turn able providers helper text*/
	const onSelectPreferent = (name, option) => {
		setAssignementModel({
			...assignementModel,
			[name]: option
		})
		setIsNecessary(true)
	}

	/**
	 * function to assigned service
	 */
	const assignedService = () => {
		if (onAssignementService) {
			onAssignementService(assignementModel)
		}
	}

	return (
		<Grid item xs={12}>
			<Typography className={classes.title}>{'IMBERA ASSIGNMENTS'}</Typography>
			{!isAuthorizadoForEditedData && (
				<>
					<ReactSelectAvatar
						id='dispatcher'
						name='dispatcher'
						placeholder={'DISPATCHER'}
						formatOptionLabel={formatOptionLabel}
						options={onDispatchersOptions}
						autoFocus
						onChange={onSelectChange}
						value={
							assignementModel.dispatcher && !!assignementModel.dispatcher.UserName
								? onDispatchersOptions.find((x) => x.value === assignementModel.dispatcher.UserName)
								: null
						}
						register={
							assignementModel && !!assignementModel.dispatcher
								? register({ name: 'dispatcher' }, { required: { value: false } })
								: register({ name: 'dispatcher' }, { required: { value: true, message: 'The dispatcher is required' } })
						}
						errors={errors}
						control={control}
						isDisabled={!isAuthorizadoForEditedData}
					/>{' '}
					<br />
				</>
			)}

			<ReactSelectAvatar
				id='techAssigned'
				name='techAssigned'
				placeholder={'TECHNICIAN'}
				options={technicianOptions}
				onChange={onSelectChange}
				formatOptionLabel={formatOptionLabel}
				value={
					assignementModel.techAssigned && !!assignementModel.techAssigned.UserName
						? technicianOptions.find((x) => x.value === assignementModel.techAssigned.UserName)
						: null
				}
				register={
					assignementModel && !!assignementModel.techAssigned
						? register({ name: 'techAssigned' }, { required: { value: false } })
						: register({ name: 'techAssigned' }, { required: { value: true, message: 'The technician is required' } })
				}
				errors={errors}
				control={control}
				isDisabled={!isAuthorizadoForEditedData}
			/>
			<br />

			{isAuthorizadoForEditedData && !preferentActive && (
				<>
					<Typography className={classes.title}>{'PREFERRED SERVICE PROVIDER'}</Typography>
					<ReactSelectAvatar
						id='prefrentServiceProvider'
						name='serviceProvider'
						placeholder={'PREFERRED SERVICE PROVIDER'}
						options={preferentProviderOption}
						onChange={onSelectPreferent}
						formatOptionLabel={formatOptionLabel}
						register={
							assignementModel && !!assignementModel.serviceProvider
								? register({ name: 'serviceProvider' }, { required: { value: false } })
								: register({ name: 'serviceProvider' }, { required: { value: true, message: 'The service provider is required' } })
						}
						errors={errors}
						control={control}
						isDisabled={!isAuthorizadoForEditedData || !(preferentProviderOption.length > 0)}
					/>
					<br />
				</>
			)}

			<Typography className={classes.title}>{'SERVICE PROVIDER'}</Typography>
			<ReactSelectAvatar
				id='serviceProvider'
				name='serviceProvider'
				placeholder={'SERVICE PROVIDER'}
				options={seviceProviderOptions}
				onChange={onSelectProvider}
				formatOptionLabel={formatOptionLabel}
				value={
					assignementModel.serviceProvider && !!assignementModel.serviceProvider.Code
						? seviceProviderOptions.find((x) => x.value === assignementModel.serviceProvider.Code)
						: null
				}
				register={
					assignementModel && !!assignementModel.serviceProvider
						? register({ name: 'serviceProvider' }, { required: { value: false } })
						: register({ name: 'serviceProvider' }, { required: { value: true, message: 'The service provider is required' } })
				}
				errors={errors}
				control={control}
				isDisabled={!isAuthorizadoForEditedData}
			/>
			{isNecessary && (
				<Typography className={classes.helperText}>
					{'If preferent service provider field is selected, this field is no longer necessary.'}
				</Typography>
			)}
			<br />

			{!isAuthorizadoForEditedData && (
				<>
					<Typography className={classes.title}>{'SERVICE PROVIDER TECHNICIAN'}</Typography>
					<ReactSelectAvatar
						id='serviceProviderTechAssigned'
						name='serviceProviderTechAssigned'
						placeholder={'TECHNICIAN PROVIDER'}
						formatOptionLabel={formatOptionLabel}
						options={onTechProvidersOptions}
						autoFocus
						onChange={onSelectChange}
						value={
							assignementModel.serviceProviderTechAssigned && !!assignementModel.serviceProviderTechAssigned.UserName
								? onTechProvidersOptions.find((x) => x.value === assignementModel.serviceProviderTechAssigned.UserName)
								: null
						}
						register={
							assignementModel && !!assignementModel.serviceProviderTechAssigned
								? register({ name: 'serviceProviderTechAssigned' }, { required: { value: false } })
								: register(
										{ name: 'serviceProviderTechAssigned' },
										{ required: { value: false, message: 'The serviceProviderTechAssigned is required' } }
								  )
						}
						errors={errors}
						control={control}
						isDisabled={!isAuthorizadoForEditedData}
					/>{' '}
					<br />
				</>
			)}

			{!isAuthorizadoForEditedData && customer && (
				<Grid item xs={6}>
					<Typography className={classes.title}>{'CUSTOMER'}</Typography>
					<Chip
						id={'value'}
						size='small'
						avatar={<AvatarCustomSize avatarUrl={null} name={'CUSTOMER'} isAvatarFromIdentity={false} />}
						label={`${customer.Code} | ${customer.Name}`}
						style={{ color: 'white', background: '#5357DC' }}
					/>
					<br />
				</Grid>
			)}

			{isAuthorizadoForEditedData && (
				<DialogActions>
					<ButtonConfirm isSaving={isSavingService || !canBeSaved()} onSavedClick={handleSubmit(assignedService)} title='CONFIRM' />
				</DialogActions>
			)}
		</Grid>
	)
}

const formatOptionLabel = ({ value, label, avatarUrl, isAvatarFromIdentity }) => (
	<div>
		<Chip
			id={value}
			size='small'
			avatar={<AvatarCustomSize avatarUrl={avatarUrl} name={label} isAvatarFromIdentity={isAvatarFromIdentity} />}
			label={label}
			clickable
			style={{ color: 'white', background: '#5357DC' }}
		/>
	</div>
)

AssignementService.propTypes = {
	isSavingService: propTypes.bool.isRequired,
	/**Function invoke callback for assigned service */
	onAssignementService: propTypes.func.isRequired,
	isAuthorizadoForEditedData: propTypes.bool.isRequired
}
AssignementService.defaultProps = {
	isSavingService: false,
	isAuthorizadoForEditedData: false,
	onAssignementService: () => console.warn('Callback [onAssignmentService] no defined')
}

export default AssignementService
