/**Import react section */
import React from 'react'
import propTypes from 'prop-types'
import { useForm } from 'react-hook-form'

/**Import Material UI Section */
import { Grid, Typography, Card, Chip } from '@material-ui/core'

/**Import componets section */
import ReactSelectCommon from '../../common/ReactSelectCommon'
import ReactSelectAvatar from '../../common/ReactSelectAvatar'
import ReactSelectCreatable from '../../common/ReactSelectCreatable'
import AvatarCustomSize from '../../common/AvatarCustomSizes'

import { GetServiceType } from '../../../store/helpers/SelectOptions'
import MapComponet from './MapComponent'
import ButtonSaved from '../../common/Buttons/ButtonSaved'
import ButtonCancel from '../../common/Buttons/ButtonCancel'
import TextBox from '../../common/TextBox'
import AlertComponent from '../../common/AlertComponent'

/**Import styles section */
import { createServicesStyle } from '../../../resources/styles/CreateServiceStyles'

import {
	CUSTOMER_MANAGER,
	CUSTOMER_TECHNICIAN,
	SALES_MANAGER,
	SALES_REPRESENTATIVE_MANAGER,
	SERVICE_PROVIDER_MANAGER,
	SERVICE_PROVIDER_TECHNICIAN
} from '../../../resources/RolesEnum'
import { hasAuthorization } from '../../../store/session/sessionActions'
/**
 * Component for show form service
 * @param {*} props
 */
const ServiceHeaderForm = (props) => {
	const classes = createServicesStyle()
	/**Functions section */
	const { onPropertyChange, onSelectChange, onChangePosition, onChangeAddress, redirectToHomeCancelService, addServiceOnClick } = props
	/**Const arrays props sections */
	const { serviceModel, representativesOptions, retailsOptions, mapPosotionModel, retailDirections, isSavingService } = props

	/**Register properties for validate information react hook form */
	const serviceType = GetServiceType()
	const { register, handleSubmit, errors, control } = useForm()
	return (
		<Grid container spacing={1}>
			<ServiceHeaderComponent
				serviceModel={serviceModel}
				representativesOptions={representativesOptions}
				retailsOptions={retailsOptions}
				serviceType={serviceType}
				onSelectChange={onSelectChange}
				register={register}
				errors={errors}
				control={control}
				userProfile={props.userProfile}
			/>
			<br />
			<br />
			<Card style={{ padding: '20px', width: '100%' }}>
				<Typography variant='overline'>
					{' '}
					<strong>{'Address'}</strong>{' '}
				</Typography>
				<Grid item xs={12} md={12}>
					<Typography variant='overline'> {'Find your address'} </Typography>
					<Grid container spacing={1}>
						<Grid item xs={12} md={6}>
							<MapComponet
								position={mapPosotionModel}
								onChangePosition={onChangePosition}
								onChangeAddress={onChangeAddress}
								directions={retailDirections}
								modeCreate={true}
							/>
						</Grid>
						<Grid item xs={12} md={6} style={{ marginTop: '-8px' }}>
							<ServiceAddress
								addressModel={serviceModel ? serviceModel.Address : {}}
								onPropertyChange={onPropertyChange}
								serviceModel={serviceModel}
								mode={'MODE_CREATE'}
								register={register}
								errors={errors}
							/>
						</Grid>
					</Grid>

					<div className={classes.buttonAdd}>
						<ButtonCancel disabled={isSavingService} onCancelClick={redirectToHomeCancelService} title='Cancel' />
						<ButtonSaved isSaving={isSavingService} onSavedClick={handleSubmit(addServiceOnClick)} title='CREATE' />
					</div>
				</Grid>
			</Card>
		</Grid>
	)
}

ServiceHeaderForm.propTypes = {
	/**Function invoke callback for set properties in the state local*/
	onPropertyChange: propTypes.func,
	/** Contains the information and properties of the service*/
	serviceModel: propTypes.object,
	/**Function invoke callback for sets representative */
	onSelectRepresentative: propTypes.func
}
ServiceHeaderForm.defaultProps = {
	onPropertyChange: () => console.warn('Callback [onPropertyChange] no defined'),
	serviceModel: {},
	onSelectRepresentative: () => console.warn('Callback [onSelectRepresentative] no defined')
}

export default ServiceHeaderForm

/**
 * Component to add header form service
 * @param {*} props
 */
const ServiceHeaderComponent = (props) => {
	const { serviceModel, representativesOptions, retailsOptions, serviceType } = props
	const { onSelectChange } = props

	/**Register properties for validate information react hook form */
	const { register, errors, control } = props

	let canBeAddRepSales = hasAuthorization(props.userProfile, [
		CUSTOMER_MANAGER,
		CUSTOMER_TECHNICIAN,
		SALES_MANAGER,
		SALES_REPRESENTATIVE_MANAGER,
		SERVICE_PROVIDER_MANAGER,
		SERVICE_PROVIDER_TECHNICIAN
	])

	return (
		<Grid item xs={12}>
			<Card style={{ padding: '20px', marginLeft: '-3px', marginRight: '-3px' }}>
				<Grid container spacing={1}>
					{!canBeAddRepSales && (
						<Grid item xs={12} sm={6}>
							<ReactSelectAvatar
								id='SalesRepresentative'
								name='SalesRepresentative'
								placeholder={'Sales representative'}
								options={representativesOptions}
								autoFocus
								formatOptionLabel={formatOptionLabel}
								onChange={onSelectChange}
								value={serviceModel ? serviceModel.SalesRepresentative : null}
								register={
									serviceModel && !!serviceModel.SalesRepresentative
										? register({ name: 'SalesRepresentative' }, { required: { value: false } })
										: register(
												{ name: 'SalesRepresentative' },
												{
													required: {
														value: true,
														message: 'The sales representative is required'
													}
												}
										  )
								}
								errors={errors}
								control={control}
							/>
						</Grid>
					)}

					<Grid item xs={12} sm={6}>
						<ReactSelectCreatable
							id='Retailer'
							name='Retailer'
							placeholder={'Retailer'}
							options={retailsOptions}
							onChange={onSelectChange}
							formatOptionLabel={formatOptionLabel}
							value={serviceModel ? serviceModel.Retailer : null}
							register={
								serviceModel && !!serviceModel.Retailer
									? register({ name: 'Retailer' }, { required: { value: false } })
									: register(
											{ name: 'Retailer' },
											{
												required: {
													value: true,
													message: 'The retail is required'
												}
											}
									  )
							}
							errors={errors}
							control={control}
						/>
					</Grid>

					<Grid item xs={12} sm={6}>
						<ReactSelectCommon
							id='serviceType'
							name='ServiceType'
							placeholder={'Service type'}
							options={serviceType}
							value={serviceModel ? serviceModel.ServiceType : null}
							onChange={onSelectChange}
							control={control}
							errors={errors}
							register={
								serviceModel && !!serviceModel.ServiceType
									? register({ name: 'ServiceType' }, { required: { value: false } })
									: register(
											{ name: 'ServiceType' },
											{
												required: {
													value: true,
													message: 'The service type is required'
												}
											}
									  )
							}
						/>
					</Grid>
				</Grid>
			</Card>
		</Grid>
	)
}

ServiceHeaderComponent.propTypes = {
	serviceModel: propTypes.object,
	representativesOptions: propTypes.arrayOf(
		propTypes.shape({
			label: propTypes.string.isRequired,
			value: propTypes.string.isRequired
		})
	),
	retailsOptions: propTypes.arrayOf(
		propTypes.shape({
			label: propTypes.string.isRequired,
			value: propTypes.string.isRequired
		})
	),
	onSelectChange: propTypes.func.isRequired
}
ServiceHeaderComponent.defaultProps = {
	representativesOptions: [],
	retailsOptions: [],
	serviceType: []
}

/**
 * Component service address form
 * @param {*} props
 */
const ServiceAddress = (props) => {
	const { onPropertyChange } = props
	const { addressModel, serviceModel } = props
	const { register, errors } = props

	/**
	 * Method to render Text Field input
	 * @param {*} sectionToken
	 * @param {*} key
	 * @param {*} item
	 * @param {*} isDisabled
	 */
	const renderTextBox = (key, item, label, isActive, isDisabled, size = 6, multiline = false, rows = 0, required, registerValues) => {
		let value = item ? (item[key] ? item[key] : '') : ''
		if (key === 'route' && value) value = `${item.street_number} ${item[key]} `

		return (
			<Grid item xs={size}>
				<TextBox
					key={key}
					active={isActive}
					required={required && errors?.[key] ? true : false}
					disabled={isDisabled}
					fullWidth
					multiline={multiline}
					name={key}
					rows={rows}
					variant='outlined'
					margin='dense'
					label={label}
					onChange={onPropertyChange}
					value={value}
					register={registerValues}
					errors={errors}
				/>
			</Grid>
		)
	}

	return (
		<Grid container spacing={1}>
			{renderTextBox(
				'country',
				addressModel,
				'Country',
				true,
				true,
				12,
				false,
				0,
				true,
				register({ required: { value: true, message: 'The country is required' } })
			)}
			{renderTextBox('administrative_area_level_1', addressModel, 'State', true, true, 8, false, 0, true, '')}
			{renderTextBox('postal_code', addressModel, 'Zip code', true, true, 4, false, 0, true, '')}
			{renderTextBox(
				'locality',
				addressModel,
				'Location',
				true,
				true,
				12,
				false,
				0,
				true,
				register({ required: { value: true, message: 'The location is required' } })
			)}
			{renderTextBox('route', addressModel, 'Street', true, true, 12, false, 0, false, '')}
			<Grid item xs={12}>
				{renderTextBox('ProblemDescription', serviceModel, 'PROBLEM DESCRIPTION', true, false, 12, true, 4, false)}
				{errors === null && (
					<AlertComponent
						title={'Error'}
						message={'Please enter the information required to continue the process.'}
						severityType={'error'}
					/>
				)}
			</Grid>
		</Grid>
	)
}

const formatOptionLabel = ({ value, label, avatarUrl, isAvatarFromIdentity }) => (
	<div>
		<Chip
			id={value}
			size='small'
			avatar={<AvatarCustomSize avatarUrl={avatarUrl} name={label} isAvatarFromIdentity={isAvatarFromIdentity} />}
			label={label}
			clickable
			style={{ color: 'white', background: '#5357DC' }}
		/>
	</div>
)

ServiceAddress.propTypes = {
	/**Function invoke callback for set properties in the state local*/
	onPropertyChange: propTypes.func,
	/** Contains the information and properties of the service*/
	addressModel: propTypes.object,
	serviceModel: propTypes.object
}
ServiceAddress.defaultProps = {
	onPropertyChange: () => console.warn('Callback [onPropertyChange] no defined'),
	addressModel: {}
}
